import React from 'react'
import GambarAbout from '../Assets/about3.jpg'
import '../About/about.css'

const about = () => {
  return (
    <>
      <div className="about-area-two  bg-image2" id="wef">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content">
                {/* <span>Apa itu WEF ?</span> */}
                <h2>
                Pameran Konsultasi Gratis Kuliah Luar Negeri
                </h2>
                <p>
                World Education Festival adalah pameran pendidikan luar negeri yang dihadiri puluhan kampus dari banyak negara. Para peserta memiliki kesempatan untuk berkonsultasi eksklusif dengan lebih dari 300 universitas luar negeri, diwakili oleh perwakilan kampus dan konsultan <a href="https://www.niecindonesia.com/" target="blank">NIEC Indonesia</a>. </p>
                
                {/* <p>
                Banyak hal menarik yang ditawarkan mulai dari informasi beasiswa luar negeri, biaya hidup di luar negeri, info gaji part time job, workshop gratis, tes kemampuan bahasa Inggris, talent mapping, translate dokumen, kursus bahasa Inggris, hingga games dan kompetisi dengan hadiah menarik seperti merchandise, e-money, hingga doorprize tiket pesawat ke Singapore.
                </p> */}
                
              </div>
            </div>
            <div className="col-lg-1 mb-5">

            </div>
            <div className="col-lg-5 mb-5">
            <div className="about-content">
                {/* <span>Apa itu WEF ?</span> */}
                <h2 className='' >Benefit Join WEF</h2>
                <div className="benefit">
                  <ul className="pricing-content">
                    <li className='pb-1' >Gratis Workshop Kampus Luar Negeri</li>
                    <li className='pb-1'>Paid Internship J1 USA, Gaji hingga 500jt/tahun</li>
                    <li className='pb-1'>Total Beasiswa Ratusan juta rupiah</li>
                    <li className='pb-1'>Gratis Kursus Bahasa Inggris</li>
                    <li className='pb-1'>Gratis IELTS/ TOEFL Simulation Test</li>
                    <li className='pb-1'>Gratis Talent Mapping (anti salah jurusan!)</li>
                    <li className='pb-1'>Dapatkan Promo & Merchandise menarik</li>
                    <li className='pb-1'>Games, Kompetisi, Snack, dan masih banyak lagi</li>
                  </ul>

                  <a href="#" className="btn btn-danger">
                    Daftar Sekarang
                  </a>
                </div>
                
              </div>
              {/* <div className="about-image">
                <img
                  src={GambarAbout}
                  className="about-img1"
                  alt="about"
                  width={500}
                  height={350}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // return (
  //   <div>
  //     <div className="description">
  //       <h6>Apa itu WEF? </h6>
  //       <h2>Pameran Konsultasi Gratis Kuliah Luar Negeri</h2>
  //       <p>World Education Festival adalah pameran pendidikan luar negeri yang dihadiri puluhan kampus dari banyak negara. Para peserta memiliki kesempatan untuk berkonsultasi eksklusif dengan lebih dari 300 universitas luar negeri, diwakili oleh perwakilan kampus dan konsultan NIEC Indonesia. Banyak hal menarik yang ditawarkan mulai dari informasi beasiswa luar negeri, biaya hidup di luar negeri, info gaji part time job, workshop gratis, tes kemampuan bahasa Inggris, talent mapping, translate dokumen, kursus bahasa Inggris, hingga games dan kompetisi dengan hadiah menarik seperti merchandise, e-money, hingga doorprize tiket pesawat ke Singapore.</p>
  //     </div>
  //     <div className="gambar-about">
  //       <img src={GambarAbout} alt="" />
  //     </div>
  //   </div>
  // )
}

export default about